import moment from 'moment'
import React from 'react'
import { Translation } from 'react-i18next'

export const TOOLTIP_CONTENT = {
  misc_cost: (
    <Translation ns="product_cost">
      {t =>
        t(
          'product_cost:tooltips.miscCost',
          'Optional Misc. cost for item prep, taxes or any other cost you would like to expense every time this item is sold'
        )
      }
    </Translation>
  ),
  total_cost: (
    <Translation ns="product_cost">
      {t =>
        t(
          'product_cost:tooltips.totalCost',
          'COGS per Unit Cost + Supplier Shipping Cost'
        )
      }
    </Translation>
  ),
  vat: (
    <Translation ns="product_cost">
      {t => t('product_cost:tooltips.vat', 'VAT % included in retail price')}
    </Translation>
  ),
  date_effective: (
    <Translation ns="product_cost">
      {t =>
        t(
          'product_cost:tooltips.dateEffective',
          'Units sold on or after this date will use this cost in calculations.'
        )
      }
    </Translation>
  ),
  lead_time_in_days: (
    <Translation ns="product_cost">
      {t =>
        t(
          'product_cost:tooltips.leadTimeInDays',
          'This is the lead time for ordering this product. This is the time between the initiation of a supply chain order and the receipt of the product at Amazon. This should include time for payment clearing, production, packing, shipping, customs clearance, etc.'
        )
      }
    </Translation>
  ),
  unit_cost: (
    <Translation ns="product_cost">
      {t => t('product_cost:tooltips.unitCost', 'Unit Cost')}
    </Translation>
  ),
  shipping_cost: (
    <Translation ns="product_cost">
      {t => t('product_cost:tooltips.shippingCost', 'Shipping Cost')}
    </Translation>
  ),
  reorder_days_of_supply: (
    <Translation ns="product_cost">
      {t =>
        t(
          'product_cost:tooltips.reorderDaysOfSupply',
          'The number of selling days you want the reordered inventory to cover. This is used to calculate your reorder quantity.'
        )
      }
    </Translation>
  )
}

export const PRODUCT_LAUNCH_DATE = moment(new Date(1978, 0, 1))
